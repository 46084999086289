// import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


function Photopage() {
   

  return( 
    <>
    <div className="Theme-page">

      <div className='top-theme'>
          <h1 className='title-photo'>Photopage</h1>
          <h2 className='main-page-photo'><Link to="/">to Main</Link></h2>

          
      </div>

    </div>
</>
    
  )
}
export default Photopage;