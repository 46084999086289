// import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


function Apppage() {
   

  return( 
    <>
    <div className="Theme-page">
      <h1>app page</h1>

    <h2 className='main-page-app'><Link to="/">to Main</Link></h2>


    </div>
</>
    
  )
}
export default Apppage;