// import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


function UIpage() {
   

  return( 
    <>
    <h1>UI page</h1>
    <div className="Theme-page">
    <h2 className='main-page-ui'><Link to="/">to Main</Link></h2>

    </div>
</>
    
  )
}
export default UIpage;